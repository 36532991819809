import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebaseConfig from "../firebase-config";

import logo from "../Assets/images/logo.svg";
import { Link } from "react-router-dom";
import Google from "../Assets/Icons/Google";
import Facebook from "../Assets/Icons/Facebook";
import Footer from "./Global/Footer";
import Header from "./Global/Header";

import logoLight from "../Assets/images/logo.svg";
import logoDark from "../Assets/images/logo-drak.svg";

import LoadingSpinner from "./spinner";

import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

// var baseURL = "https://us-central1-structure-lab.cloudfunctions.net";

var baseURL = "http://localhost:5001/structure-lab/us-central1";

firebase.initializeApp(firebaseConfig);

export default function SignUp({ theme, setTheme }) {
  const { id } = useParams();

  console.log(id);

  let [disable, setDisable] = useState(false);

  let [plan, setPlan] = useState({});

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (id){
      axios
      .post(`${baseURL + "/fetchItem"}`, { id: id })
      ?.then((res) => {
        setPlan(res?.data?.details);
      })
      .catch((err) => {
        console.log(err?.response?.data);
      });
    }
    else{
      window.open('https://www.structurelab.ai/early-access', '_self')
    }
  }, [id]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    let tempObj = object;

    tempObj["instrument"] = selectedValue;

    setObject(tempObj);
  };

  const options = [
    {
      id: 1,
      label: "Crypto",
      children: ["BTC/USD", "ETH/USD", "MATIC/USD", "XRP/USD"],
    },
    {
      id: 2,
      label: "Forex",
      children: [
        "EUR/USD",
        "GBP/USD",
        "AUD/USD",
        "USD/CHF",
        "USD/JPY",
        "NZD/USD",
      ],
    },
    { id: 3, label: "Metals", children: ["XAU/USD", "XAG/USD"] },
  ];

  let [object, setObject] = useState({
    name: "",
    email: "",
    isSocial: false,
    password: "",
    plan: id,
    terms: false,
  });

  let [error, SetError] = useState({
    show: false,
    message: "",
  });

  const signInWithGoogle = async (e) => {
    e.preventDefault();

    if (!selectedOption && id?.includes("Basic")) {
      alert("Please Choose Instrument");
      return;
    }

    if (!object?.terms) {
      alert("Please agree to Terms");
      return;
    }
    setObject((old) => {
      return {
        ...old,
        name: "",
        password: "",
      };
    });

    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;
      let tempObj = object;
      tempObj["name"] = user.displayName;
      tempObj["email"] = user.email;
      tempObj["uid"] = user?.uid;
      tempObj["isSocial"] = true;

      setObject(tempObj);

      requestHit(object);
    } catch (err) {
      console.error(error.err);
    }
  };

  const changeListener = (value, field) => {
    console.log(value, field);

    let tempObj = object;

    if (field === "terms") {
      tempObj[field] = !object.terms;
    } else {
      tempObj[field] = value;
    }

    setObject(tempObj);
  };

  const submitHandle = (e) => {
    e.preventDefault();

    if (!object?.email || !object?.password) {
      alert("Please Fill All Field");
      return;
    }

    if (!selectedOption && id?.includes("Basic")) {
      alert("Please Choose Instrument");
      return;
    }

    if (!object?.terms) {
      alert("Please agree to Terms");
      return;
    }

    requestHit({ ...object, isSocial: false });
  };

  const requestHit = (obj) => {
    setDisable(true);
    axios
      .post(`${baseURL}/${obj?.isSocial ? "socialLogin" : "createUser"}`, obj)
      ?.then((res) => {
        console.log(res);
        setDisable(false);

        window.location.href = res?.data?.url;
      })
      .catch((err) => {
        console.log(err?.response?.data);

        let tempErr = error;

        tempErr.show = true;
        tempErr.message = err?.response?.data;

        alert(tempErr.message);

        setDisable(false);
      });
  };

  console.log(error);

  return (
    <>
    {
      id ? (<div className="sing-area">
      <Header logo={theme === "light-theme" ? logoLight : logoDark} />
      <div>
        <form action="" className="sign-form">
          <div className="sign-box">
            <div className="form-title">Sign up</div>
            <div className="form-subtitle">in less than 2 min</div>

            {id?.includes("Basic") ? (
              <div className="form-floating">
                {/* <label htmlFor="dropdown">Select an Instrument: </label>{" "} */}
                <select
                  className="form-control"
                  onChange={handleSelectChange}
                  value={selectedOption || ""}
                >
                  <option value="" disabled>
                    Select an Instrument
                  </option>

                  {options.map((option) => (
                    <optgroup key={option.id} label={option.label}>
                      {option.children.map((child, index) => (
                        <option key={index} value={child}>
                          {child}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}

            <div className="form-floating">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                onChange={(e) => {
                  changeListener(e.target.value, "email");
                }}
              />
              <label for="floatingInput">Email address</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                onChange={(e) => {
                  changeListener(e.target.value, "password");
                }}
              />
              <label for="floatingPassword">Password</label>
            </div>
            <div className="text-center">
              <div class="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  // checked={object?.terms}
                  id="check-id"
                  onChange={(e) => {
                    changeListener(e.target.checked, "terms");
                  }}
                />
                <label className="form-check-label" for="check-id">
                  I agree to the <Link to="">Terms & Conditions</Link>
                </label>
              </div>
              <button
                disabled={disable}
                onClick={submitHandle}
                className="btn-lg submit"
                type="submit"
              >
                Sign Up
              </button>

              <div className="sign-social">
                <button disabled={disable} onClick={signInWithGoogle}>
                  <Google /> Sign up with Google
                </button>
                {/* <button disabled={disable}>
                  <Facebook /> Sign up with Facebook
                </button> */}
                {disable ? <LoadingSpinner /> : ""}
              </div>
              <p className="account">
                Already have an account?{" "}
                <Link to="https://app.structurelab.ai/">Sign in</Link>
              </p>
            </div>
          </div>
        </form>
      </div>
      <Footer
        theme={theme}
        setTheme={setTheme}
        logo={theme === "light-theme" ? logoLight : logoDark}
      />
    </div>) : ""
    }
    </>
  );
}
