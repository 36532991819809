import React from "react";
import { Link } from "react-router-dom";

const Header = ({ logo }) => {
  return (
    <div className="sign-header">
      <Link to="/"><img src={logo} alt="" height={40} /></Link>
    </div>
  );
};

export default Header;