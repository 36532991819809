import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SignUp from "./components/signup.component";

function App() {
  const [theme, setTheme] = useState("light-theme");

  useEffect(() => {
    if (theme === "dark-theme") {
      document.querySelector("body").setAttribute("data-theme", "dark-theme");
    } else {
      document.querySelector("body").setAttribute("data-theme", "light-theme");
    }
  }, [theme]);

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    setTheme(localTheme);
  }, []);
  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Routes>
            <Route
                path="/"
                element={<SignUp theme={theme} setTheme={setTheme} />}
              />
              <Route
                path="/:id"
                element={<SignUp theme={theme} setTheme={setTheme} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}
export default App;
