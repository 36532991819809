import React from "react";
import Sun from "../../Assets/Icons/Sun";
import Moon from "../../Assets/Icons/Moon";
import { Link } from "react-router-dom";

const Footer = ({ theme, setTheme, logo }) => {
  return (
    <div className="mt-auto">
      <div className="sign-footer">
        <Link to="/"><img src={logo} alt="" height={40} /></Link>
        <div className="dark_mode">
          <input
            className="dark_mode_input"
            type="checkbox"
            id="darkmode-toggle"
            onChange={(e) => {
              setTheme(e.target.checked ? "dark-theme" : "light-theme");
              localStorage.setItem(
                "theme",
                e.target.checked ? "dark-theme" : "light-theme"
              );
            }}
            checked={theme === "dark-theme"}
          />
          <label className="dark_mode_label" htmlFor="darkmode-toggle">
            <Sun />
            <Moon />
          </label>
        </div>
        <p>Copyright 2024 Structure Lab</p>
      </div>
    </div>
  );
};

export default Footer;
