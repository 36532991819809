const firebaseConfig = {
  apiKey: "AIzaSyCDnlufr9ItzyOGIG1aQndhvVbKFFc1ZdA",
  authDomain: "structure-lab.firebaseapp.com",
  databaseURL: "https://structure-lab-default-rtdb.firebaseio.com",
  projectId: "structure-lab",
  storageBucket: "structure-lab.appspot.com",
  messagingSenderId: "146070427726",
  appId: "1:146070427726:web:3bfc6b950347e0e8a00b1f",
  measurementId: "G-QKT6Q8FS5P"
};

export default firebaseConfig;